var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d2-container-full" }, [
    _vm.$slots.header
      ? _c(
          "div",
          { ref: "header", staticClass: "d2-container-full__header" },
          [_vm._t("header")],
          2
        )
      : _vm._e(),
    _c(
      "div",
      {
        ref: "body",
        staticClass: "d2-container-full__body",
        class: { isSlide: _vm.isSlide }
      },
      [_c("div", { staticClass: "t" }, [_vm._t("default")], 2)]
    ),
    _vm.$slots.footer
      ? _c(
          "div",
          { ref: "footer", staticClass: "d2-container-full__footer" },
          [_vm._t("footer")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }