var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          staticStyle: { "pointer-events": "auto" },
          attrs: {
            title: "角色",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            "modal-append-to-body": true,
            modal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.rewriteClearData()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "popover" },
            _vm._l(_vm.appListAllList, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "itemList" },
                [
                  _c("img", {
                    staticClass: "itemList-i-i",
                    attrs: { src: _vm.$utils.imgTrow(item.icon) }
                  }),
                  _c("div", { staticClass: "itemList-i-t " }, [
                    _vm._v(_vm._s(item.name))
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { "margin-top": "16px" },
                      attrs: { size: "small" },
                      model: {
                        value: item.Selection,
                        callback: function($$v) {
                          _vm.$set(item, "Selection", $$v)
                        },
                        expression: "item.Selection"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: true, border: "" } }, [
                        _vm._v("开启")
                      ]),
                      _c("el-radio", { attrs: { label: false, border: "" } }, [
                        _vm._v("关闭")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }