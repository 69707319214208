var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: _vm.formData.id ? "修改" : "新增",
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        modal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.clearData()
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "140px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "应用", prop: "appid" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.formData.appid,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "appid", $$v)
                    },
                    expression: "formData.appid"
                  }
                },
                _vm._l(_vm.appListAllData, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.appid }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "发件人名称", prop: "from_name" }
            },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.from_name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "from_name", $$v)
                  },
                  expression: "formData.from_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              staticStyle: { width: "100%" },
              attrs: { label: "邮件服务器地址", prop: "server_host" }
            },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.server_host,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "server_host", $$v)
                  },
                  expression: "formData.server_host"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "邮件主题", prop: "subject" }
            },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.subject,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "subject", $$v)
                  },
                  expression: "formData.subject"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "邮件服务器用户名", prop: "user" }
            },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.user,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "user", $$v)
                  },
                  expression: "formData.user"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "邮件服务器密码", prop: "password" }
            },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.password,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "password", $$v)
                  },
                  expression: "formData.password"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "邮件服务器端口", prop: "server_port" }
            },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.server_port,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "server_port", $$v)
                  },
                  expression: "formData.server_port"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { label: "邮件模板", prop: "tpl" }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 6,
                  clearable: "",
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.formData.tpl,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "tpl", $$v)
                  },
                  expression: "formData.tpl"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { round: "" },
              on: {
                click: function($event) {
                  _vm.dialogFormVisible = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "warning", round: "" },
              on: {
                click: function($event) {
                  return _vm.clearData(false)
                }
              }
            },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit("ruleForm")
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }