export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 网关配置列表
    appGatewaySdkConfigData: function appGatewaySdkConfigData(data) {
      return request({
        url: '/apGatewaySdkConfig/appGatewaySdkConfigData',
        method: 'get',
        data: data
      });
    },
    // 网关配置新增
    appGatewaySdkConfigAdd: function appGatewaySdkConfigAdd(data) {
      return request({
        url: '/apGatewaySdkConfig/appGatewaySdkConfigAdd',
        method: 'post',
        data: data
      });
    },
    // 网关配置修改
    appGatewaySdkConfigUpdate: function appGatewaySdkConfigUpdate(data) {
      return request({
        url: '/apGatewaySdkConfig/appGatewaySdkConfigUpdate',
        method: 'post',
        data: data
      });
    },
    // 网关配置删除
    appGatewaySdkConfigDel: function appGatewaySdkConfigDel(data) {
      return request({
        url: '/apGatewaySdkConfig/appGatewaySdkConfigDel',
        method: 'post',
        data: data
      });
    },
    // 网关参数切换列表
    appGatewayReplaceListData: function appGatewayReplaceListData(data) {
      return request({
        url: '/appGatewayReplaceList/appGatewayReplaceListData',
        method: 'get',
        data: data
      });
    },
    // 网关参数切换新增
    appGatewayReplaceListAdd: function appGatewayReplaceListAdd(data) {
      return request({
        url: '/appGatewayReplaceList/appGatewayReplaceListAdd',
        method: 'post',
        data: data
      });
    },
    // 网关参数切换修改
    appGatewayReplaceListUpdate: function appGatewayReplaceListUpdate(data) {
      return request({
        url: '/appGatewayReplaceList/appGatewayReplaceListUpdate',
        method: 'post',
        data: data
      });
    },
    // 网关参数切换删除
    appGatewayReplaceListDel: function appGatewayReplaceListDel(data) {
      return request({
        url: '/appGatewayReplaceList/appGatewayReplaceListDel',
        method: 'post',
        data: data
      });
    },
    // 网关订单列表
    gateOrderList: function gateOrderList(data) {
      return request({
        url: '/sdkGateOrderList/gateOrderList',
        method: 'get',
        data: data
      });
    },
    // 网关用户列表
    gateUserList: function gateUserList(data) {
      return request({
        url: '/sdkGateOrderList/gateUserList',
        method: 'get',
        data: data
      });
    },
    // 网关用户状态修改
    gateUserBan: function gateUserBan(data) {
      return request({
        url: '/sdkGateOrderList/gateUserBan',
        method: 'post',
        data: data
      });
    },
    // 网关用户修改密码
    updAccountPassword: function updAccountPassword(data) {
      return request({
        url: '/sdkLoginDataList/updAccountPassword',
        method: 'post',
        data: data
      });
    },
    // 网关重置jwt
    GatewaySdkresetJwt: function GatewaySdkresetJwt(data) {
      return request({
        url: '/apGatewaySdkConfig/resetJwt',
        method: 'post',
        data: data
      });
    },
    // 新增账号
    registerUser: function registerUser(data) {
      return request({
        url: '/sdkLoginDataList/registerUser',
        method: 'post',
        data: data
      });
    },
    // 修改账号
    updAccountInfo: function updAccountInfo(data) {
      return request({
        url: '/sdkLoginDataList/updAccountInfo',
        method: 'post',
        data: data
      });
    }
  };
});