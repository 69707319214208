//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { loadMoreList, tool, commonData } from "@/mixins/index";
export default {
  name: "touristLogin",
  mixins: [loadMoreList, tool, commonData],
  data: function data() {
    return {
      paramsData: {
        isMustAppid: 1
      },
      pagingReqFn: "visitorList"
    };
  },
  created: function created() {},
  activated: function activated() {
    this.getcommonData(['appListAllData']);
  },
  methods: {}
};