import "core-js/modules/es.array.find-index";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import viewArticles from "./components/viewArticles.vue";
import { loadMoreList, commonData } from "@/mixins/index";
export default {
  name: "gatewayUser",
  mixins: [loadMoreList, commonData],
  components: {
    viewArticles: viewArticles
  },
  data: function data() {
    return {
      datePicker: [],
      pagingReqFn: "gateUserList",
      userStatus: [{
        label: "有效",
        value: 1
      }, {
        label: "无效",
        value: 2
      }],
      //订单状态
      paramsData: {
        isMustAppid: 1
      }
    };
  },
  activated: function activated() {
    this.getcommonData(['appListAllData']);
  },
  methods: {
    userStatusChange: function userStatusChange(e) {
      var _this = this;

      var status = e.status == 1 ? 2 : 1;
      this.$api.gateUserBan({
        appid: e.appid,
        id: e.id,
        status: status,
        isMustAppid: 1
      }).then(function (res) {
        if (res.status == 200) {
          var index = _this.tableData.findIndex(function (i) {
            return i.id == e.id;
          });

          _this.tableData[index].status = status;

          _this.$message.success("操作成功");
        }
      });
    }
  }
};