import "core-js/modules/es.array.slice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "customBox",
  props: {
    title: {
      type: String,
      default: function _default() {
        return '详情';
      }
    },
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    return {
      dialogShow: false,
      windowHeight: 830,
      // 窗口固定高度
      windowHeightNew: 0,
      // 窗口新高度
      scrollDistance: 0,
      // 鼠标滚动距离
      paddingTop: 0,
      paddingBut: 0,
      randString: ''
    };
  },
  watch: {
    scrollDistance: function scrollDistance(newVal) {
      this.paddingTop = newVal;
      this.paddingBut = this.windowHeightNew - this.windowHeight - newVal;
    },
    visible: function visible(newVal) {
      var _this = this;

      this.dialogShow = newVal;

      if (newVal) {
        this.$nextTick(function () {
          _this.windowHeightNew = document.getElementById('dialogs' + _this.randString).clientHeight;
          _this.paddingTop = _this.scrollDistance;
          _this.paddingBut = _this.windowHeightNew - _this.windowHeight - _this.scrollDistance < 0 ? 0 : _this.windowHeightNew - _this.windowHeight - _this.scrollDistance;
        });
        this.$bus.$emit('isSlide', true);
      }

      if (!newVal) {
        this.$bus.$emit('isSlide', false);
        this.$emit('close');
      }
    }
  },
  created: function created() {
    var _this2 = this;

    this.$bus.$on('scroll', function (res) {
      _this2.scrollDistance = res.y;
    });
    this.randString = this.getRandomString();
  },
  methods: {
    init: function init() {
      this.windowHeight = 830, // 窗口固定高度
      this.windowHeightNew = 0, // 窗口新高度
      this.scrollDistance = 0, // 鼠标滚动距离
      this.paddingTop = 0, this.paddingBut = 0, this.randString = 0;
    },
    afterEnter: function afterEnter() {
      this.$emit('opened');
    },
    afterLeave: function afterLeave() {
      this.$emit('closed');
    },
    closeDialo: function closeDialo() {
      this.dialogShow = false;
      this.$emit('update:visible', false);
      this.$emit('close');
    },
    getRandomString: function getRandomString(len) {
      var min = Math.random().toString(36).slice(-8);
      return min;
    }
  }
};