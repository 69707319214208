import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { loadMoreList, commonData } from "@/mixins/index";
import addLoginConfiguration from "./components/addLoginConfiguration";
export default {
  name: "loginConfiguration",
  mixins: [loadMoreList, commonData],
  components: {
    addLoginConfiguration: addLoginConfiguration
  },
  data: function data() {
    return {
      pagingReqFn: "appLoginSdkConfigData",
      delIds: [],
      paramsData: {
        isMustAppid: 2
      }
    };
  },
  activated: function activated() {
    this.getcommonData(['appListAllData']);
    this.getList();
  },
  methods: {
    reset: function reset(row, index, type) {
      var _this = this;

      this.$confirm('请确认当前操作?', '确认', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.$api.resetLogin({
          id: row.id,
          type: type
        }).then(function (res) {
          if (res.status == 200) {
            _this.$message.success('重置成功');

            _this.tableData[index][type == 'jwt' ? 'jwt_secret' : 'password_salt'] = res.data;
          }
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(e) {
      this.delIds = e;
    },
    del: function del(dateList) {
      var _this2 = this;

      var ids = dateList.map(function (item) {
        return item.id;
      }).toString();
      this.$store.dispatch('d2admin/method/deleteList', {
        req: 'appLoginSdkConfigDel',
        data: {
          id: ids
        }
      }).then(function () {
        _this2.getList(_this2.paramsData.page);
      });
    }
  }
};