import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import _objectSpread from "D:/work/admin/sdk_manage_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import axios from 'axios';
import Adapter from 'axios-mock-adapter';
import { get } from 'lodash';
import util from '@/libs/util';
import store from '@/store/index';
import { Message } from 'element-ui';

function encodeURIParams(config, url) {
  var urls = url;
  urls += '?';
  var keys = Object.keys(config);

  for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
    var key = _keys[_i];
    urls += "".concat(key, "=").concat(encodeURIComponent(config[key]), "&");
  }

  urls = urls.substring(0, urls.length - 1);
  return urls;
}
/**
 * @description 创建请求实例
 */


function createService() {
  // 创建一个 axios 实例
  var service = axios.create(); // 请求拦截

  service.interceptors.request.use(function (config) {
    var paramsData = config.data ? _objectSpread({}, config.data) : {};

    if (paramsData) {
      for (var i in paramsData) {
        if (paramsData[i] === '' || Array.prototype.isPrototypeOf(paramsData[i]) && !paramsData[i].length || Object.prototype.isPrototypeOf(paramsData[i]) && Object.keys(paramsData[i]).length === 0 || paramsData[i] == null) {
          delete paramsData[i];
        }
      }
    } // 


    if (config.method == 'post') {
      config.data = paramsData; // 转为formdata数据格式
    }

    if (config.method == 'get' && config.data) {
      config.url = encodeURIParams(paramsData, config.url);
    }

    config.params = '';
    config.headers.Authorization = 'Bearer ' + (util.cookies.get('token') || '');
    return config;
  }, function (error) {
    // 发送失败
    console.log(error);
    return Promise.reject(error);
  }); // 响应拦截

  service.interceptors.response.use(function (response) {
    var dataAxios = response.data;
    var status = dataAxios.status,
        message = dataAxios.message;

    if (status === undefined) {
      return dataAxios;
    } else {
      switch (status) {
        case 200:
          break;

        case 1000:
          break;

        case 400:
          Message({
            type: "error",
            message: message
          });
          break;

        case 500:
          Message({
            type: "error",
            message: message
          });
          break;

        default:
          Message({
            type: "error",
            message: message
          });
          break;
      }

      return dataAxios;
    }
  }, function (error) {
    var status = get(error, 'response.status');
    var data = get(error, 'response.data');
    console.log(error);
    console.log(data);
    console.log(status);

    switch (status) {
      case 400:
        error.message = '请求错误';
        break;

      case 401:
        Message({
          type: "error",
          message: '登录已失效,请重新登录'
        });
        setTimeout(function () {
          store.dispatch('d2admin/account/logout');
        }, 500);
        break;

      case 403:
        error.message = '拒绝访问';
        break;

      case 404:
        error.message = "\u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url);
        break;

      case 408:
        error.message = '请求超时';
        break;

      case 500:
        error.message = message;
        Message({
          type: "error",
          message: message
        });
        break;

      case 501:
        error.message = '服务未实现';
        break;

      case 502:
        error.message = '网关错误';
        break;

      case 503:
        error.message = '服务不可用';
        break;

      case 504:
        error.message = '网关超时';
        break;

      case 505:
        error.message = 'HTTP版本不受支持';
        break;

      default:
        break;
    }

    return Promise.reject(data);
  });
  return service;
}
/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */


function createRequestFunction(service) {
  return function (config) {
    var token = util.cookies.get('token');
    var configDefault = {
      headers: {
        'Authorization': 'Bearer ' + (token || '')
      },
      timeout: 300000,
      baseURL: process.env.VUE_APP_API
    };
    return service(Object.assign(configDefault, config));
  };
} // 用于真实网络请求的实例和请求方法


export var service = createService();
export var request = createRequestFunction(service); // 用于模拟网络请求的实例和请求方法

export var serviceForMock = createService();
export var requestForMock = createRequestFunction(serviceForMock); // 网络请求数据模拟工具

export var mock = new Adapter(serviceForMock);