//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { loadMoreList, tool, commonData } from "@/mixins/index";
import passwordEdit from "./components/passwordEdit";
import addAccount from "./components/addAccount";
import accountEdit from "./components/accountEdit";
export default {
  name: "accountLogin",
  mixins: [loadMoreList, tool, commonData],
  components: {
    passwordEdit: passwordEdit,
    addAccount: addAccount,
    accountEdit: accountEdit
  },
  data: function data() {
    return {
      pagingReqFn: "accountList",
      paramsData: {
        isMustAppid: 1
      }
    };
  },
  created: function created() {},
  activated: function activated() {
    this.getcommonData(['appListAllData']);
  },
  methods: {
    getListNew: function getListNew(e) {
      if (this.paramsData.appid) {
        this.getList(e);
      }
    }
  }
};